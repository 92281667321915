import React, { ReactElement } from 'react'

import Login from '@/contents/Login'
import { Main } from '@/layouts'
import { makeStaticProps } from '@/lib/getStatic'
import { NextPageWithLayout } from '@/pages/_app'

const LoginPage: NextPageWithLayout = () => <Login />

export default LoginPage

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return (
    <Main footer={<Login.Footer />} header={<Login.Header />}>
      {page}
    </Main>
  )
}

const getStaticProps = makeStaticProps()
export { getStaticProps }
